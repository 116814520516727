import { strings } from 'res';
import { DashboardIcon, InsightIcon, Megaphone, OrderIcon, ReportIcon, SLAIcon } from "../assets/svg/icon";
import { UserOutlined, DownCircleOutlined, InfoCircleOutlined, BookOutlined } from '@ant-design/icons';

const dashBoardNavTree = [{
  key: "Home",
  path: strings.navigation.path.dashboard,
  title: "Home",
  breadcrumb: false,
  submenu: [
    {
      key: "Dashboard",
      path: strings.navigation.path.dashboard,
      title: "Dashboard",
      icon: DashboardIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Contents",
      path: strings.navigation.path.contents,
      title: "Contents",
      icon: ReportIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Layanan",
      path: strings.navigation.path.layanan,
      title: "Layanan",
      icon: InsightIcon,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Berita",
      path: strings.navigation.path.berita,
      title: "Berita",
      icon: InfoCircleOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Unduhan",
      path: strings.navigation.path.unduhan,
      title: "Unduhan",
      icon: DownCircleOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Pengumuman",
      path: strings.navigation.path.pengumuman,
      title: "Pengumuman",
      icon: Megaphone,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Kelurahan",
      path: strings.navigation.path.kelurahan,
      title: "Kelurahan",
      icon: SLAIcon,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: "Categories",
    //   path: strings.navigation.path.categories,
    //   title: "Categories",
    //   icon: OrderIcon,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: "Users",
      path: strings.navigation.path.users,
      title: "Users",
      icon: UserOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: "Laporan",
      path: strings.navigation.path.laporan,
      title: "Laporan",
      icon: BookOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

// const ControlTree = [{
//   key: "Pengaturan",
//   path: strings.navigation.path.settings,
//   title: "Pengaturan",
//   breadcrumb: false,
//   submenu: [
//     {
//       key: "Pengaturan",
//       path: strings.navigation.path.settings,
//       title: "Pengaturan",
//       icon: SettingIcon,
//       breadcrumb: false,
//       submenu: []
//     }
//   ]
// }]

const navigationConfig = [
  ...dashBoardNavTree,
  // ...ControlTree,
]

export default navigationConfig;