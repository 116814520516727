import { Col, Row, message } from 'antd';
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Select } from 'antd';
import { addContent, fetchOneContent, updateContent, deleteContent } from 'redux/features/content';
import { MyEditor } from '../../../components/Editor'
import { fetchAllCategory } from 'redux/features/category';
import ImageUploader from 'components/shared-components/ImageUploader';
import Utils from 'utils';


const selectStyle = {
  width: "100%",
  backgroundColor: "white"
};

const rules = [
  {
    required: true,
    message: 'Wajib Diisi!',
  }
]

const options = [
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'user',
    label: 'User',
  }
]

export const ADD_CONTENT = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const location = useLocation()

  const [body, setBody] = useState("")
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [imgUrl, setImgUrl] = useState("");

  const handleChange = (value) => {
    setSelectedCategory(value)
  };

  const onFinish = (values) => {
    if (location?.state?.id) {
      updateData(values)
    } else {
      createData(values)
    }
  }

  const createData = async (values) => {
    console.log("create data :", values)
    try {
      await dispatch(addContent({
        "user_id": 1,
        "title": values.title,
        "thumbnail": imgUrl,
        "description": values.description,
        "file": values.file,
        "body": body,
        "category_id": 3
      })).unwrap()
      history.push("/app/pengumuman")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const updateData = async (values) => {
    try {
      await dispatch(updateContent({
        "user_id": 1,
        "id":location?.state?.id,
        "title": values.title,
        "thumbnail": imgUrl,
        "description": values.description,
        "category_id": 3,
        "file": values.file,
        "body": body
      })).unwrap()
      history.push("/app/pengumuman")
    } catch (error) {
      message.error(error?.message || 'Failed to fetch data')
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const getDataById = async (id) => {
    try {
      const data = await dispatch(fetchOneContent(id)).unwrap()
      form.setFieldsValue({
        ...data.data,
        category: data.data.category_id
      });
      setBody(data?.data.body)
      setImgUrl(data.data.thumbnail)
    } catch (err) {
      console.log(err)
    }
  }

  const getCategories = async () => {
    try {
        const response = await dispatch(fetchAllCategory()).unwrap()
        setCategories(response.data.map(category => {
          return {
            value: category.id,
            label: category.name
          }
        }))
    } catch (error) {
        message.error(error?.message || 'Failed to fetch data')
    }
  }

  const handleImageUpload = (info) => {

    if (info.file.status === 'uploading') {
      // setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      setImgUrl(info.file.response.secure_url)
    }
  }

  useEffect(() => {
    if (location?.state?.id) {
      getDataById(location?.state?.id)
    }
    getCategories();
  }, [])

  return (
    <>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Tambahkan/Ubah Pengumuman</h2>
          <p>Mohon isikan informasi Pengumuman</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Form
              name="basic"
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: 'Please input your title!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Thumbnail"
                name="thumbnail"
                rules={[
                  {
                    required: true,
                    message: 'Please upload your Thumbnail',
                  },
                ]}
              >
                <ImageUploader imageUrl={imgUrl} onChange={handleImageUpload}/>
              </Form.Item>

              <Form.Item
                label="File URL"
                name="file"
                rules={[
                  {
                    required: true,
                    message: 'Please input your file URL!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Description (Max 255 Characters)"
                name="description"
                rules={[
                  {
                    required: true,
                    message: 'Please input your description!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <MyEditor data={body} setState={setBody} ></MyEditor>
              </Form.Item>

              <Form.Item

              >
                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ADD_CONTENT