import moment from "moment-timezone";

export const strings = {
  today: moment().tz("Asia/Jakarta").format("YYYY-MM-DD"),
  tomorrow: moment().add(1, 'days').tz("Asia/Jakarta").format("YYYY-MM-DD"),
  token: localStorage.getItem('token'),
  api: {
    host: "https://api.pamekasankab.info/api/v1",
    storage: "https://storage.surabah.tech/upload",
    HEADER_KEY: "mantapjiwa",
    JWT_KEY: "Aj1257Xi202",
  },
  image: {
    authBackground: 'url(/img/background.png)',
    logo: "/img/logo-colored.svg",
    logo2: "/img/logo-colored.svg",
    favicon: "",
    primaryColor: "#1445e4",
    buttonColor: "#6e17f7",
    color1: "#0675b5",
    color2: "#10a8f0",
    color3: "#0077b6",
    color4: "#FF0000",
    color5: "#005578",
    color6: "#FFAA00"
  },
  auth_form: {
    isUnavailableAccount: "Belum punya akun? ",
    sign_up: "Daftar",
    sign_in: "Masuk",
    alreadyHaveAccount: "Sudah punya akun? ",
    create_account: "Create a new account:",
  },
  default: {
    title_app: "Dashboard",
  },
  navigation: {
    login: '/auth/login',
    register: '/auth/register',
    main: '/app',
    path: {
      login: 'login',
      register: 'register',
      forgot_password: 'forgot-password',
      activate: 'activate',
      resend:"resend",
      please: 'please',
      logout: "logout",
      dashboard: "/app/dashboard",
      contents: "/app/contents",
      layanan: '/app/layanan',
      unduhan: '/app/unduhan',
      pengumuman: '/app/pengumuman',
      berita: '/app/berita',
      kelurahan: '/app/kelurahan',
      laporan: '/app/laporan',
      categorization: "/app/categorization",
      detail_content: "/app/detail-content",
      detail_layanan: "/app/detail-layanan",
      detail_unduhan: "/app/detail-unduhan",
      detail_berita: "/app/detail-berita",
      detail_pengumuman: "/app/detail-pengumuman",
      detail_kelurahan: "/app/detail-kelurahan",
      categories: "/app/categories",
      detail_category: "/app/detail-category",
      detail_categorization: "/app/detail-categorization",
      detail_user: "/app/detail-user",
      users: "/app/users",
      profile: "/app/profile",
      settings: "/app/settings"
    }
  }
};
