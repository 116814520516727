import { Button, Card, Col, message, Row, Table, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState, useMemo } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import { strings } from "res";
import { fetchAllContent, deleteContent } from "redux/features/content";

import moment from "moment";
const LocalizedModal = () => {
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const hideModal = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal
        title="Modal"
        open={open}
        onOk={hideModal}
        onCancel={hideModal}
        okText="确认"
        cancelText="取消"
      >
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
        <p>Bla bla ...</p>
      </Modal>
    </>
  );
};

export const CONTENTS = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);
  const [data, setData] = useState();
  const [metaData, setMetaData] = useState({});
  const [modal, contextHolder] = Modal.useModal();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 10,
    sort: "created_at",
    category_id: 3,
  });

  const tableColumns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (_, record) => (
        <div style={{ margin: "auto" }}>
          <img src={record.thumbnail} style={{ maxWidth: "15%" }}></img>
        </div>
      ),
    },
    {
      title: "Tanggal",
      dataIndex: "dibuat",
      key: "dibuat",
      render: (_, record) =>
        record.created_at
          ? moment(record.created_at).format("DD-MM-YYYY")
          : "-",
    },
    {
      title: "Jam",
      dataIndex: "dibuat",
      key: "dibuat",
      render: (_, record) =>
        record.created_at ? moment(record.created_at).format("HH:MM:SS") : "-",
    },
    {
      title: () => <div className="text-center">Detail</div>,
      key: "detail",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="primary"
            style={{ textAlign: "center" }}
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_pengumuman}`,
                state: record,
              });
            }}
          >
            Detail
          </Button>
        </div>
      ),
    },
    {
      title: () => <div className="text-center">Action</div>,
      key: "status",
      render: (_, record) => (
        <div className="text-center">
          <Button
            type="danger"
            style={{ textAlign: "center", color: "white" }}
            onClick={() => {
              confirm(record.id);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const getData = async (params) => {
    try {
      setLoading(true);
      const response = await dispatch(
        fetchAllContent({
          category_id: 3,
          sort: "",
        })
      ).unwrap();
      setData(response.data);
      setMetaData(response.meta.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error?.message || "Failed to fetch data");
    }
  };

  const confirm = (id) => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure wanna delete this?",
      okText: "Yes",
      cancelText: "No",
      onOk: async () => {
        await dispatch(deleteContent(id));
        getData(filters);
      },
      onCancel: () => {},
    });
  };

  const handlePageChange = (page) => {
    setFilters({ ...filters, page: page.current });
  };

  useEffect(() => {
    getData(filters);
  }, []);

  useMemo(() => {
    getData(filters);
  }, [filters]);

  return (
    <>
      <LocalizedModal></LocalizedModal>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <h2>Pengumuman</h2>
          <p>Semua pengumuman</p>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Card>
            <Table
              className="no-border-last"
              style={{ textAlign: "center", margin: "auto" }}
              columns={tableColumns}
              dataSource={data}
              rowKey="id"
              pagination={{
                defaultCurrent: 1,
                total: metaData.total_data,
              }}
              onChange={handlePageChange}
              loading={isLoading}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <Button
            type="primary"
            style={{ border: "0px" }}
            htmlType="submit"
            onClick={() => {
              history.push({
                pathname: `${strings.navigation.path.detail_pengumuman}`,
              });
            }}
            block
          >
            Tambahkan Pengumuman Baru
          </Button>
        </Col>
      </Row>
      {contextHolder}
    </>
  );
};

export default withRouter(CONTENTS);
